import { useEffect, useContext, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Row, Col } from 'antd'
import { FirestoreContext } from '../Firebase/firestoreContext'
import Breadcrumb from '../Breadcrumb'
import { collection, getDocs, orderBy, query } from '@firebase/firestore'

const ViewConnectors = () => {
  const [quickFilterText, setQuickFilterText] = useState('')
  const [connectorList, setConnectorList] = useState([])
  const [, setGridApi] = useState(null)
  const [, setGridColumnApi] = useState(null)
  const { firestore, userDoc } = useContext(FirestoreContext)

  useEffect(() => {
    // Retrieve Client's stores
    if (userDoc) {
      const connectors = []

      const q = query(
        collection(firestore, 'users', userDoc.id, 'stores'),
        orderBy('platform')
      )
      getDocs(q).then(querySnapshot => {
        querySnapshot.forEach(doc => {
          connectors.push(doc.data())
        })
        setConnectorList(connectors)
      })
    }
  }, [userDoc, firestore])

  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const handleRowSelected = e => {
    // TODO

  }

  const handleQuickFilterTextChanged = e => {
    setQuickFilterText(e.target.value)
  }

  return (
    <>
      {/* <Header className='site-layout-background' style={{ padding: 0 }} /> */}
      <Breadcrumb
        pages={[{ name: 'View Connectors', href: '#', current: true }]}
        hidden={false}
      />
      <div
        className='site-layout-background h-full'
        style={{ padding: 24, minHeight: 360 }}
      >
        <Row style={{ paddingBottom: 10 }}>
          <Col sm={24} lg={8}>
            <input
              type='text'
              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              onChange={handleQuickFilterTextChanged}
              placeholder='Search'
            />
          </Col>
        </Row>
        <Row>
          <div
            className='ag-theme-alpine '
            style={{ height: '70vh', width: '100%' }}
          >
            <AgGridReact
              defaultColDef={{ resizable: true }}
              quickFilterText={quickFilterText}
              rowSelection='single'
              onRowDoubleClicked={handleRowSelected}
              onGridReady={onGridReady}
              rowData={connectorList}
            >
              <AgGridColumn field='storeURL' flex={1} filter />
              <AgGridColumn field='platform' flex={1} filter />
              <AgGridColumn field='active' filter flex={1} />
            </AgGridReact>
          </div>
        </Row>
      </div>
    </>
  )
}

export default ViewConnectors
